
export const GENDER_CHOICES = [
    {en: "Male", jp: "男性"},
    {en: "Female", jp: "女性"},
]

export const LANGUAGE_CHOICES = [
    {label: "English", value: "en"},
    {label: "日本語", value: "jp"},
]


export const DAYOFWEEK_CHOICES = [
    {en:'Sunday', jp:'日曜日'},
    {en:'Monday', jp:'月曜日'},
    {en:'Tuesday', jp:'火曜日'},
    {en:'Wednesday', jp:'水曜日'},
    {en:'Thursday', jp:'木曜日'},
    {en:'Friday', jp:'金曜日'},
    {en:'Saturday', jp:'土曜日'},
]

export const PREFECTURE_CHOICES = [
    {jp:'北海道',en:'Hokkaido'},
    {jp:'青森県',en:'Aomori'},
    {jp:'岩手県',en:'Iwate'},
    {jp:'宮城県',en:'Miyagi'},
    {jp:'秋田県',en:'Akita'},
    {jp:'山形県',en:'Yamagata'},
    {jp:'福島県',en:'Fukushima'},
    {jp:'茨城県',en:'Ibaraki'},
    {jp:'栃木県',en:'Tochigi'},
    {jp:'群馬県',en:'Gunma'},
    {jp:'埼玉県',en:'Saitama'},
    {jp:'千葉県',en:'Chiba'},
    {jp:'東京都',en:'Tokyo'},
    {jp:'神奈川県',en:'Kanagawa'},
    {jp:'富山県',en:'Toyama'},
    {jp:'石川県',en:'Ishikawa'},
    {jp:'福井県',en:'Fukui'},
    {jp:'新潟県',en:'Niigata'},
    {jp:'山梨県',en:'Yamanashi'},
    {jp:'長野県',en:'Nagano'},
    {jp:'岐阜県',en:'Gifu'},
    {jp:'静岡県',en:'Shizuoka'},
    {jp:'愛知県',en:'Aichi'},
    {jp:'三重県',en:'Mie'},
    {jp:'滋賀県',en:'Shiga'},
    {jp:'京都府',en:'Kyoto'},
    {jp:'大阪府',en:'Osaka'},
    {jp:'兵庫県',en:'Hyogo'},
    {jp:'奈良県',en:'Nara'},
    {jp:'和歌山県',en:'Wakayama'},
    {jp:'鳥取県',en:'Tottori'},
    {jp:'島根県',en:'Shimane'},
    {jp:'岡山県',en:'Okayama'},
    {jp:'広島県',en:'Hiroshima'},
    {jp:'山口県',en:'Yamaguchi'},
    {jp:'徳島県',en:'Tokushima'},
    {jp:'香川県',en:'Kagawa'},
    {jp:'愛媛県',en:'Ehime'},
    {jp:'高知県',en:'Kochi'},
    {jp:'福岡県',en:'Fukuoka'},
    {jp:'佐賀県',en:'Saga'},
    {jp:'長崎県',en:'Nagasaki'},
    {jp:'熊本県',en:'Kumamoto'},
    {jp:'大分県',en:'Oita'},
    {jp:'宮崎県',en:'Miyazaki'},
    {jp:'鹿児島県',en:'Kagoshima'},
    {jp:'沖縄県',en:'Okinawa'},
]





export const INSTRUCTOR_GENRE_CHOICES = [
    {en:'Breaking', jp:'ブレイキン'},
    {en:'Popping', jp:'ポップ'},
    {en:'Locking', jp:'ロック'},
    {en:'Hip-Hop', jp:'ヒップホップ'},
    {en:'Krump', jp:'クランプ'},
    {en:'House', jp:'ハウス'},
    {en:'A-Pop', jp:'A-Pop'},
]

export const LESSON_GENRE_CHOICES = [
    {en:'Breaking', jp:'ブレイキン'},
    {en:'Popping', jp:'ポップ'},
    {en:'Locking', jp:'ロック'},
    {en:'Hip-Hop', jp:'ヒップホップ'},
    {en:'Krump', jp:'クランプ'},
    {en:'House', jp:'ハウス'},
    {en:'A-Pop', jp:'A-Pop'},
    {en:'Rhythm Training', jp:'リズムトレーニング'},
    // {en:'Stretch', jp:'ストレッチ'},
]



export const GENRE_CHOICES = [
    {en:'Breaking', jp:'ブレイキン'},
    {en:'Popping', jp:'ポップ'},
    {en:'Locking', jp:'ロック'},
    {en:'Hip-Hop', jp:'ヒップホップ'},
    {en:'Krump', jp:'クランプ'},
    {en:'House', jp:'ハウス'},
    {en:'A-Pop', jp:'A-Pop'},
    {en:'Rhythm Training', jp:'リズムトレーニング'},
]





export const TARGET_AUDIENCE_CHOICES = [
    {en:'everyone', jp:'制限なし'},
    {en:'kids (preschool)', jp:'キッズ（未就学児）'},
    {en:'kids (K1-12)', jp:'キッズ（小学生以上）'},
    {en:'adults', jp:'大人'},
    {en:'beginners', jp:'初心者'},
    {en:'first-time', jp:'未経験'},
    {en:'custom', jp:'その他'},
]

export const IS_INSTRUCTOR_CHOICES = [
    {
        en: 'I will be teaching this lesson', 
        jp: 'はい。私がレッスンを行います。', 
        value: true
    },
    {
        en: 'Someone else will be teaching this lesson', 
        jp: 'いいえ。他のインストラクターに依頼します。', 
        value: false
    },
]




export const CANCELLATION_REASON_CHOICES = [
    {en: 'Not interested', jp:'興味ない'},
    {en: 'Too expensive', jp:'値段が高い'},
    {en: 'Location is too far', jp:'場所が遠すぎる'},
    {en: 'Scheduling problem', jp:'日付の問題'},
    {en: 'Other reason', jp:'その他'},
]



export const INSTRUCTOR_LESSON_STATUS_CHOICES = [
    {en: 'active', jp:'受講予定'},
    {en: 'in-progress', jp:'受講中'},
    {en: 'inactive', jp:'受講済み'},
    {en: 'draft', jp:'下書き'},
]
