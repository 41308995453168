
interface BouncingLoaderProps {
    className?: string;
}


export const BouncingLoader = ({
    className
}:BouncingLoaderProps) => {
    return (
        <div className={`flex justify-center ${className}`}>
            <div className="w-3 h-3 mx-1 bg-gray-400 rounded-full animate-loader"></div>
            <div className="w-3 h-3 mx-1 bg-gray-400 rounded-full animate-loader animation-delay-200"></div>
            <div className="w-3 h-3 mx-1 bg-gray-400 rounded-full animate-loader animation-delay-400"></div>
        </div>
    )
}